import React from "react";
import { Link } from "react-router-dom";

import Img from "../../assets/faq_transparent4.png";

const Home = () => (
  <>
    <img src={Img} alt="" className="RightFloatImage" />
    <p>
      Според кварталните легенди, Джоро е митично същество, което обитава ж.к.
      "Надежда". Легендите не дават достатъчно информация дали е човек,
      гигантски мутирал хамстер, китайски робот с изкуствен интелект, октоподо-подобно
      извънземно или нещо съвсем свръхестествено.
    </p>
    <p>
      Точна информация за Джоро се събира трудно, по няколко причини:
    </p>
    <ul>
      <li>
        повечето очевидци на предполагаеми появявания на Джоро са били пияни по
        време на събитието
      </li>
      <li>повечето очевидци твърдят, че са видяли Джоро през нощта</li>
      <li>всичко се случва много бързо и никой няма време да направи снимки</li>
      <li>
        легендите за Джоро обикновено не се записват, а се предават от уста на
        уста
      </li>
    </ul>
    <p>
      Някои надежденски учени считат, че Джоро не е нещо конкретно, а просто събирателен
      образ, народен герой, архетип, идея. Но предвид страховитите неща, които говорят
      легендите за Джоро, за ваша лична безопасност е най-добре да приемете, че
      Джоро е реална и непосредствена опасност, от която трябва да се пазите
      денонощно.
    </p>
    Когато попитате жител на жк. "Надежда": "Добре де, колко може да е опасен тоя ваш Джоро?", вероятно той ще ви погледне със сериозен поглед и тихо ще отвърне: "Никой не е оцелял, за да разкаже".
    <p className="Important">
      Защото ако и когато научим цялата истина, може вече да е твърде късно...
    </p>
    <Link to="/nachalo-na-legendata">
      Тук можете да прочетете как се предполага, че е възникнала легендата за
      Джоро
    </Link>
  </>
);

export default Home;
