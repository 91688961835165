import React from "react";

import Img from "../../assets/puzzled-guys.jpg";

const FAQ = () => (
  <>
    <img src={Img} alt="" className="RightFloatImage" />
    <dl>
      <dt></dt>
      <dd></dd>
      <dt>Може ли Джоро да лети в Космоса?</dt>
      <dd>
        Въпреки, че според някои Надежденски учени Халеевата комета всъщност е самият Джоро,
        движещ се зверски гладен с висока скорост към баничарница, все още няма
        категорични доказателства, че Джоро обитава места извън планетата Земя.
      </dd>
      <dt>Само един екземпляр Джоро ли съществува в природата?</dt>
      <dd>
        Пу-пу-пу! Да спи зло под камък! Пепел ви на устата! Само това оставаше -
        Джоро да се размножи! Не викайте дявола!
      </dd>
      <dt>Може ли Джоро да се види в някоя зоологическата градина?</dt>
      <dd>
        Вие сърце нямате ли?! Не е хуманно да се излагат невинните животни на
        присъствието на Джоро!
      </dd>
      <dt>Каква е разликата между Джоро и обикновения петнист маждрамуняк?</dt>
      <dd>
        Обикновеният петнист маждрамуняк никога не управлява автомобил{" "}
        <i>след</i> като е пил алкохол, а Джоро никога не управлява автомобил{" "}
        <i>преди</i> да е пил алкохол.
      </dd>
      <dt>Каква е разликата между Джоро и тасманийския дявол?</dt>
      <dd>
        Тасманийски дявол, макар и много трудно, все пак се подава на
        опитомяване.
      </dd>
      <dt>Расист ли е Джоро?</dt>
      <dd>
        Според легендите, Джоро мрази всички земни раси, включително бялата.
        Предполага се, че той вярва в съществуването на извънземни раси, само и
        само да може да мрази една или няколко раси в повече.
      </dd>
      <dt>Има ли Джоро проблем с алкохола?</dt>
      <dd>
        Не, абсолютно никакъв. Алкохолът обаче има сериозен проблем в лицето
        Джоро.
      </dd>
      <dt>
        Обича ли Джоро да носи между зъбите си подхвърлени дървени пръчки?
      </dt>
      <dd>
        Не, и си избийте завинаги от главата мисълта, че можете да превърнете
        Джоро в домашен любимец!
      </dd>
      <dt>Колко глави има Джоро?</dt>
      <dd>
        Зависи от деня от седмицата. В понеделник - една, в сряда - 3 и
        половина, а в събота - николко, защото в петък вечерта се е “отрязал от
        пиене”.
      </dd>
      <dt>Кога ще тръгне по кината филмът “Легендата за Джоро”?</dt>
      <dd>
        Този филм противоречи на няколко Женевски конвенции, Законът за ВиК
        дружествата, Законът за земната гравитация, Пакта за ненападение между
        Япония и Китай, както и на Наредба номер 25678 на Столичната община за
        забрана на паркирането на автомобили върху улични фонтани. Затова не е
        сигурно, че изобщо някога ще го видим по екраните.
      </dd>
    </dl>
  </>
);

export default FAQ;
