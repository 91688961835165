import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons";
import "./Page.css";

const Page = ({ navLinks, page, pageName, pageContent, shouldScrollTop }) => {
  const [isNavOpen, toggleNav] = useState(false);
  const [isCookiesInfoClosed, setCookiesInfoClosed] = useState(null);

  const appClasses =
    "App" +
    (isNavOpen ? " NavOpen" : " NavClosed") +
    (isCookiesInfoClosed ? " CookiesInfoClosed" : "");

  const contentDiv = useRef();

  const scrollToPageTop = () => {
    contentDiv.current.scrollTop = 0;
  };

  const closeCookiesInfo = () => {
    localStorage.setItem("cookiesInfoClosed", "true");
    setCookiesInfoClosed(true);
  };

  const checkIsCookiesInfoClosed = () => {
    return localStorage.getItem("cookiesInfoClosed");
  };

  // const isCookiesInfoClosed = checkIsCookiesInfoClosed();

  useEffect(() => {
    console.log("????");
    if (!isNavOpen && shouldScrollTop) {
      scrollToPageTop();
    }
  });

  useEffect(() => {
    const isCookiesInfoClosed = checkIsCookiesInfoClosed();

    if (isCookiesInfoClosed) {
      setCookiesInfoClosed(true);
      return;
    }

    setCookiesInfoClosed(false);
  }, []);

  console.log('@!#@@', isCookiesInfoClosed);

  return (
    <>
      <div className={appClasses}>
        <nav className="Nav" onClick={() => toggleNav(false)}>
          <div className="NavMenuIconWrapper">
            <Link className="HomeLink" to="/">
              Djoro.info
            </Link>
            <button
              className="NavMenuIcon"
              disabled={!isNavOpen}
              onClick={() => toggleNav(currentState => !currentState)}
            >
              <FontAwesomeIcon icon={isNavOpen ? faTimes : faBars} />
            </button>
          </div>
          <ul>{navLinks}</ul>
        </nav>

        <div className="ContentBlock">
          <header className="Header">
            <div className="HeaderMain">
              <button
                className="NavMenuIcon"
                // disabled={isNavOpen}
                onClick={() => toggleNav(currentState => !currentState)}
              >
                <FontAwesomeIcon icon={isNavOpen ? faTimes : faBars} />
              </button>
              <Link className="HomeLink" to="/">
                Djoro.info
              </Link>
            </div>
            <div className="HeaderText">
              <span>... всичко, което искате да знаете за Джоро, </span>
              <span>но ви е страх да попитате</span>
            </div>
          </header>
          <main className="MainContent" ref={contentDiv}>
            <article className="Content">
              {page && pageName && <h1>{pageName}</h1>}
              {pageContent}
              <div>
                <button onClick={scrollToPageTop} className="ScrollToTop">
                  <span className="Icon">&uarr;</span> нагоре
                </button>
              </div>
            </article>
          </main>
        </div>
      </div>
        {isCookiesInfoClosed !== null && (
        <div className="CookiesInfo">
          Този сайт НЕ използва бисквитки, защото се говори, че Джоро обича
          бисквитки, а ние за нищо на света не искаме да привличаме вниманието
          му. <button onClick={closeCookiesInfo}>OK</button>
        </div>
      )}
    </>
  );
};

export default Page;
