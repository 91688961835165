import React from "react";

import Img from "./images/badgers.jpg";
import styles from './Home.module.css';

const Home = () => (
  <div className={styles.HomeAd}>
    <header>реклама</header>
    <h2>Ресторант "Борсушка среща"</h2>
    <img src={Img} alt="" />
    <h3>Традиционна язовска кухня:</h3>
    <ul>
      <li>Земни червеи върху канапе от гнили горски плодове</li>
      <li>Ларви на бръмбар със сос от дъбови листа и репеи</li>
      <li>Крем супа от гущери с див чесън</li>
      <li>Омлет от яйца и мухлясали корени</li>
    </ul>
    <p>Меню за ценители в центъра на "Надежда"!</p>
    <address>Адрес: ул. "Пълен батак" No33</address>
  </div>
);

export default Home;
