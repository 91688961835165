import React from "react";

import Img from "../../assets/fearful-guy.jpg";

const Endangered = () => (
  <>
    <img src={Img} alt="" className="RightFloatImage" />
    <p>
      Абсолютно всеки е застрашен от Джоро! Даже ако накрая се окаже, че Джоро
      не съществува, той въпреки всичко е изключително опасен дори като
      измислица! За ваша безопасност, спазвайте следните правила:
    </p>
    <ul>
      <li>
        Никога не ходете в кв. “Надежда”. За по-сигурно не ходете в България. А
        най-сигурният възможен (но не напълно гарантиращ спасение) план е да
        напуснете планетата Земя незабавно и да се надявате, че в Космоса сте в
        безопасност…
      </li>
      <li>
        Независимо къде се намирате и кое време на денонощието е, ако чуете зад
        себе си гърлен звук “Джънгър-джънгър-ихууу”, придружен от шум
        наподобяващ скърцащи накладки на “Рено Лагуна”, веднага бягайте!
        Вероятно вече е късно за бягство, но поне ще знаете, че преди неизбежния
        край сте се опитали да се спасите.
      </li>
      <li>
        Никога не се доближавайте на по-малко от километър от магазин продаващ
        алкохол - тези места според легендите се посещават от Джоро по няколко
        пъти на ден.
      </li>
      <li>
        По възможност не дишайте! Джоро винаги усеща, когато някой някъде по
        Земята диша, и това ужасно го дразни.
      </li>
    </ul>
  </>
);

export default Endangered;
