export const routes = {
  "": {
    name: "Начало",
    contentComponent: "Home",
    displayInMenu: true,
  },
  "kakvo-e-djoro": {
    name: "Какво е Джоро?",
    contentComponent: "What",
    displayInMenu: true,
  },
  "nachalo-na-legendata": {
    name: "Начало на легендата за Джоро",
    contentComponent: "Beginning",
    displayInMenu: false,
  },
  svidetelstva: {
    name: "Свидетелства",
    contentComponent: "Testimonials",
    displayInMenu: true,
  },
  jivot: {
    name: "Мита за Джоро в ежедневието",
    contentComponent: "Life",
    displayInMenu: true,
  },
  "pravila-za-bezopasnost": {
    name: "Правила за безопасност",
    contentComponent: "Safety",
    displayInMenu: true,
  },
  faq: {
    name: "Често задавани въпроси",
    contentComponent: "FAQ",
    displayInMenu: true,
  },
  404: {
    name: "Грешка 404",
    contentComponent: "Error404",
    displayInMenu: false,
  },
};
