import React from "react";

import style from './Quote.module.css';

const Quote = ({author, children}) => (
    <blockquote className={style.Quote}>
      <p>
       {children}
      </p>
      <footer>
        — <cite>{author}</cite>
      </footer>
    </blockquote>
);

export default Quote;
