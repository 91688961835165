import React, { useEffect } from "react";
import { useHistory, Link, Route, Switch, Redirect } from "react-router-dom";

import { routes } from "./routes";
import * as ContentPages from "./components/content";
import Main from "./components/content/Page/Page";

const App = () => {
  const routerHistory = useHistory();
  const currentPath = routerHistory.location.pathname;
  const page = currentPath.replace(/^\/+/g, "");
  const pageName = routes[page]?.name;

  const contentComponentName = routes[page]?.contentComponent;
  const ContentComponent = ContentPages[contentComponentName];

  const navLinks = Object.keys(routes)
    .filter(key => routes[key].displayInMenu)
    .map(key => (
      <li key={key}>
        <Link to={key}>{routes[key].name}</Link>
      </li>
    ));

  useEffect(() => {
    if (!ContentComponent) {
      // console.log('?????');
      routerHistory.replace("/404");
    }
  });

  if (!ContentComponent) {
    console.log("?????");
    //routerHistory.replace("/404");
    return null;
  }

  const pageContent = <ContentComponent />;

  console.log(routerHistory);

  const routeElements = Object.keys(routes).map(key => (
    <Route key={key} exact path={`/${key}`}>
      <Main
        navLinks={navLinks}
        page={key}
        pageName={pageName}
        pageContent={pageContent}
        shouldScrollTop={key !== page}
      />
    </Route>
  ));

  return (
    <Switch>
      {routeElements}
      {/* <Redirect to="/404" /> */}
    </Switch>
  );
};

export default App;
