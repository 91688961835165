import React from "react";
import {  Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";

import AdHome from "../../ads/Home";
import styles from "./Home.module.css";

const Home = () => (
  <div className={styles.HomeWrapper}>
    <div className={styles.HomeIntro}>
      <p>
        Добре дошли на сайта с възможно най-изчерпателна информация за митичното
        Надежденско същество Джоро!
      </p>

      <section className={styles.HomeWarning}>
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className={styles.HomeWarningIcon}
        />
        <p className={styles.HomeWarningText}>
          Огледайте се, ослушайте се, и ако Джоро не се задава отникъде,
          продължете да четете спокойно.
          <span className={styles.HomeWarningLinkWrapper}><Link to="/pravila-za-bezopasnost">Как да разбера дали Джоро се задава отнякъде?</Link></span>
        </p>
        
      </section>
    </div>
    <aside>
      <AdHome />
    </aside>
  </div>
);

export default Home;
