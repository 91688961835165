import React from "react";
import { Link } from "react-router-dom";

import Img from "../../assets/faq_transparent4.png";

const Beginning = () => (
  <>
    <img src={Img} alt="" className="RightFloatImage" />
    <p>Предполага се, че началото на легендата е поставено така...</p>
    <p>
      Една нощ, преди 7 години, докато пиели пред денонощен магазин в квартала,
      трима жители на ж.к. "Надежда" видяли към тях да се приближава с бясна
      скорост обект, които се движел на зигзаг и светел с няколко цвята
      светлинки. Обектът спрял на метър от тях, от него започнали да падат
      отвертки и отвътре се чула неясна псувня.
    </p>
    <p>
      След малко от задния багажник излязло нещо, за което тримата очевидци
      дават различни описания, като не могат да постигнат съгласие за броя
      глави, антените на главата, наличието/липсата на пипала, и прочие.
      "Съществото" се отправило към магазина, псувайки усърдно, но неясно.
    </p>
    <p>
      Поискало на чист български език бутилка уиски, платило си, след което се
      запътило обратно към транспортното си средство. Най-пияният очевидец се
      осмелил да запита "Кой си ти?". Последвал отговор, който тримата предават
      ориентировъчно като "Дж-дж-дж-оро". После "съществото" влязло в багажника
      и след малко транспортното средство потеглило. След като изтрезнели,
      тримата единодушно решили, че то много е приличало на Рено Лагуна.
    </p>
    <p>
      До края на следващия ден половин "Надежда" вече знаела за страховитата случка. И оттогава започнали да се трупат нови и нови  <Link to="/svidetelstva">свидетелства</Link>...
    </p>
  </>
);

export default Beginning;
