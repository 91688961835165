import React from "react";

import Img from "../../assets/surprised-monkey.jpg";
import Quote from '../Quote/Quote';

const Testimonials = () => (
  <>
    <img src={Img} alt="" className="RightFloatImage" />
      <div>
        <Quote author="Пилот на &quot;Борсук Еър&quot;">
          &quot;Тъкмо бяхме излетяли в полунощ от летище София и самолетът преминаваше над жк Надежда  на височина около 2км, когато иззад един облак внезапно изскочи Рено Лагуна и ни засече. От единия прозорец на Реното се подаде нещо неясно, което сякаш имаше пет или шест усти и ми се стори, че ни псува с поне три от тях, но беше тъмно и не съм сигурен. След малко Реното изчезна, предполагам, че е било свръхзвуково.&quot;
        </Quote>
        <Quote author="Иван Мисирков - Фантата">
          &quot;Пия си аз кротко пред денонощния на &quot;Ломско шосе&quot;, и по едно време чувам един такъв плашещ звук "Джънгър-джънгър-джънгър". Оставям бутилката и се оглеждам в тъмното - уж няма никой. Посягам пак към бутилката и напипвам вместо нея нещо голямо и леко бодливо, което изведнъж проговори с човешки глас: "Мммммм, опипваме, а?". Сърцето ми направо спря да бие! Без да погледна назад, си плюх на петите и така спрях чак на Жълтите павета. &quot;
        </Quote>
        <Quote author="Димитър Коларски, инструктор по шофиране">
          &quot;Една вечер се прибирах от кормуване покрай &quot;Северен парк&quot;, аз карах, но в колата имаше един курсант, който щях да закарам до тях, защото ми беше на път. По едно време забелязах как по дънера на едно дърво в парка бавно слиза едно Рено Лагуна. Изумях, защото съм инструктор 33 години, но нито в обучението си, нито в практиката си съм чувал и виждал такова нещо. А курсантът пребледня и каза: &quot;Аз това Рено Лагуна май го познавам от &quot;Надежда!&quot;. Реното се приближи и на прозореца на шофьора се показаха някакви шорти, май имаше палми по тях. Като ги видя, курсантът направо откачи и извика: &quot;Г-н Коларски, това са шортите на Джоро страшилището на &quot;Надежда!&quot;, да бягаме оттук преди да сме загазили!&quot;. Не разбрах какво точно иска да каже момчето, но беше толкова уплашено, че за по-сигурно дадох газ и без да спазвам светофари и предимства, максимално бързо се озовах в &quot;Долни Богров&quot;, където паркирах колата в едно блато с жаби, и чак на сутринта се осмелих да изляза от нея.&quot;
        </Quote>
        <Quote author="Бай Ставри - Тутманика, баничар">
          &quot;Петък сутрин беше, много рано, тъкмо бях извадил тавата с баницата от фурната и си бях пийнал за отскок 300 грама водка, и чувам по едно време нещо тропа из баничарницата, ама понеже осветлението беше слабо, нищо не мога да видя. Светнах с джобното фенерче, и що да видя - нещо с ей такава голяма глава и ей такива големи опулени очи захапало тавата в края и се опитва да я сдъвче заедно с баницата! Смразих се, ей! Бил съм касапин, ловец, чистил съм градската канализация, даже бях за малко в политиката, ама такова чудо не бях виждал! А онова нещо като ме видя, излая нещо набързо с конски глас, хвърли ми 20 лева на тезгяха, натъпка си джобовете на пижамата с банички и изчезна! &quot;
        </Quote>
      </div>
  </>
);

export default Testimonials;
