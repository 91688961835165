import React from "react";

import Img from "../../assets/shocked-people.jpg";

const Life = () => (
  <>
    <img src={Img} alt="" className="RightFloatImage" />
    <p>
      Легендата за Джоро има преки отражения върху ежедневната комуникация на
      жителите на жк. Надежда.
    </p>
    <p>
      Така например, обичайно е на детските площадки да се чуе как майки се карат на децата си с думите "Ако не слушаш, ще те дам на Джоро да те превърне в маймуна!".
    </p>
    <p>
      Често, когато искат да намекнат на мъжете си за личната им хигиена, жените от жк Надежда им казват полугалено-полукритично: "Ама и ти си замирисал като Джоро!".
    </p>
    <p>
      Когато някой говори прекалено високо, без да се съобразява с околните, е обичайно да бъде смъмрен с думите "Стига си вил като Джоро на пълнолуние!"
    </p>
    <p>
      Ако се случи пиян Надежденски шофьор да паркира колата си върху друга кола, после познатите му вероятно поне месец ще му натякват "Джоро ли те е учил да паркираш?".
    </p>
    <p>
      Изразът "Натъпках се като гладен Джоро" значи "Преядох прекалено". 
    </p>
    <p>
      В една Надежденска кръчма поне преди време имаше предложение "Меню Джоро", което включваше салата с домати и печени пилешки бутчета, агнешка супа с луканка и боб, баница с кайма и кашкавал, 200 гр мастика, и за десерт - суджук на скара с пържени картофи. Твърди се, че не рядко се случва, след като са консумирали това меню, мъже от жк Надежда да чупят прозорци чрез изпускане на стомашни газове.
    </p>
  </>
);

export default Life;
